
$platform-name: 'legacyclubofsouthaustraliaandbrokenhillinc';


// $brand-primary: #2A3588;
$brand-primary: #24294D;
$brand-secondary: #C62406;
$brand-tertiary: #5365C6;
$brand-four: #24294D;
$donate-colour: $brand-secondary;
$black: #000;
$link-colour: #2A3588;

// layout
$container-max-width: 1230px;
$gap-width: 40px;
$site-bleed: 20px;
$border-radius: 3px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;


// Logo
$logo: 'logo-legacy.png';
$logo-width: 681px;
$logo-height: 115px;
// $logo-mobile: 'logo_stacked.svg';
// $logo-mobile-width: 67px;
// $logo-mobile-height: 90px;

// Admin menu
$menu-admin-background-colour: $brand-four;
$menu-admin-link-colour: lighten($brand-tertiary, 20%);
$menu-admin-manager-link-enabled: false;
$menu-admin-absolute: false;

// Header
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-background-colour: $brand-four;
$header-search-enabled: false;
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: #fff;
$social-icons-font-size: 25px;
$header-content-padding-top: $spacer;
$header-content-padding-bottom: $spacer;

$cta-btn-background-colour: $brand-tertiary;

// Nav
$nav-background-colour: #fff;
$nav-normal-max-width: 100%;
$nav-normal-margin-bottom: 0;
$nav-normal-border-radius: 0;

// Nav top level
$nav-top-level-item-colour: $brand-primary;
$nav-top-level-item-padding: 10px 15px;
// Submenus
$nav-submenu-item-hover-background-colour: lighten($brand-primary, 55%);

// Nav Mobile
$nav-normal-mobile-background-colour: $brand-tertiary;




// // Logo
// $logo: 'logo.svg';
// $logo-width: 250px;
// $logo-height: 94px;
// // $logo-mobile: 'logo_stacked.svg';
// // $logo-mobile-width: 67px;
// // $logo-mobile-height: 90px;
//
// // Admin menu
// $menu-admin-background-colour: transparent;
// $menu-admin-manager-link-enabled: false;
// $menu-admin-absolute: true;
//
// // Header
// $header-search-enabled: false;
// $social-icons-background-colour: transparent;
// $social-icons-colour: $brand-tertiary;
// $header-content-padding-top: 10px;
//
// // Nav
// $nav-background-colour: $brand-tertiary;
// $nav-normal-max-width: 100%;
// $nav-normal-margin-bottom: 0;
// $nav-normal-border-radius: 0;
//
// // Nav top level
// $nav-top-level-item-colour: #fff;
//
// // Submenus
// $nav-submenu-item-hover-background-colour: lighten($brand-primary, 55%);
//
// // Nav Mobile
// $nav-normal-mobile-background-colour: $brand-tertiary;

// Typography
$font-family-base: 'Helvetica Now Text W01 Regular', arial, sans-serif;
$headings-font-family: 'Helvetica Now Display W01 Bold', arial, sans-serif;
$font-size-h4: 1.5em;
$headings-colour: $brand-four;

// Buttons
$btn-font-family: $font-family-base;
$btn-padding-x: 1.2em;
$btn-padding-y: .6em;

// Carousel
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-details-max-width: 100%;
$carousel-details-min-height: 100%;
$carousel-image-overlay: rgba(0,0,0,0.35);
$carousel-details-background-colour: transparent;
$carousel-details-position-y: center;
$carousel-details-position-x: left;
$carousel-details-text-align: left;
$carousel-border-radius: 0;
// $carousel-details-padding: 0 0 0 100px;

$carousel-heading-font-size: 4.5rem;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;
$carousel-summary-enabled: false;

$carousel-read-more-background-colour: $brand-secondary;

$carousel-controls-enabled: false;
$carousel-controls-button-border-radius: 0;

$carousel-dots-position-x: right;
$carousel-dots-padding: $spacer * 2;
$carousel-dots-active-colour: $brand-tertiary;

// Carousel scroll button
$carousel-scroll-btn-enabled: true;
$carousel-scroll-btn-size: 40px;
$carousel-scroll-btn-background-colour: transparent;
$carousel-scroll-btn-border-radius: 0;
$carousel-scroll-btn-box-shadow: none;
$carousel-scroll-btn-icon-colour: $white;
$carousel-scroll-btn-icon-font-size: 1.2em;
$carousel-scroll-btn-margin-y: 0;
$carousel-scroll-btn-animation-prefab: 0;

// Home intro
// $home-intro-max-width: $container-max-width;
$home-intro-content-max-width: 800px;
// $home-intro-content-align: left;
$home-intro-background-colour: transparent;
$home-intro-margin-top: 0;
$home-intro-font-family: $headings-font-family;
//$home-intro-font-weight: normal;
$home-intro-font-size: 1.2rem;
$home-intro-padding-y: $spacer * 4;


// Home Features
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 5;
$home-features-cards-entry-animation-prefab: 1;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;


// Home feeds
$home-feeds-background-colour: #fff;
$home-feeds-padding-top: $spacer * 5;
$home-feeds-padding-bottom: $spacer * 2;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Impact stats
$impact-stats-padding-y: $spacer * 5;
$impact-stats-figure-colour: #fff;
$impact-stats-background-colour: $black;
$impact-stats-background-image: "stats_bg.jpg";
$impact-stats-background-image-opacity: 0.6;

// Home QG panel
$home-quick-giving-background-colour: $brand-four;
$home-quick-giving-heading-colour: #fff;
$home-quick-giving-donation-amount-background-colour: #fff;
$home-quick-giving-donation-amount-selected-background-colour: darken($brand-primary, 10);
$home-quick-giving-donate-btn-background-colour: $brand-secondary;
$home-quick-giving-padding-y: $spacer * 3;

// Footer
$footer-boxes-count: 4;
$footer-prefab: 3;
$footer-background-colour: $brand-four;
$footer-link-colour: #fff;
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: #fff;
$social-icons-footer-hover-colour: $brand-four;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-heading-text-align: left;
$newsletter-heading-font-size: $font-size-h4;
$newsletter-heading-colour: #fff;
$newsletter-colour: #fff;

// Blockquotes
$blockquote-background-colour: #f0f0f0;
$blockquote-colour: $brand-four;
$blockquote-padding: $spacer * 4;
$blockquote-font-size: 1.2em;
$blockquote-text-align: left;

// Sidebars - off
$sidebar-enabled: false;
$post-content-max-width: 980px;
$sidebar-layout: below;

// Listing pages
$listing-intro-max-width: 980px;

// Donation amounts
$donation-amount-min-width: 220px;

// Subsite logo
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: false;
$subsite-hide-header-search: false;
$subsite-hide-main-cta: false;
$subsite-content-max-width: $container-max-width;
$subsite-logo: 'title';

// Subsite nav
$subsite-nav-breakpoint: map-get($breakpoints, lg);
$subsite-nav-max-width: 100%;
$subsite-nav-margin-top: 0;
$subsite-nav-margin-bottom: $spacer;
$subsite-nav-background-colour: $brand-primary;
$subsite-nav-contents-max-width: $container-max-width;
$subsite-nav-align-items: center;
$subsite-nav-top-level-item-padding: 12.5px 20px;
$subsite-nav-top-level-item-colour: text-contrast($brand-primary);
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family !default;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size !default;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
$subsite-nav-top-level-item-background-colour: transparent;
$subsite-nav-top-level-item-hover-colour: $subsite-nav-top-level-item-colour;
$subsite-nav-top-level-item-hover-background-colour: rgba(255, 255, 255, 0.075);
